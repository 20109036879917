import React from 'react'
import { Text, Link as Wrapper, Center, VStack } from '@chakra-ui/react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

const Thank = () => {
  return (
    <Layout>
      <Center>
        <VStack>
          <Text fontSize='xl' py={10}>
            Thanks, I'll get back to you soon!
          </Text>

          <Text py={10}>
            <Wrapper>
              <Link to='/'>Back to home</Link>
            </Wrapper>
          </Text>
        </VStack>
      </Center>
    </Layout>
  )
}
export default Thank
